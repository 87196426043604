body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.submit {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  background-color: #003366;
  color: white;
  cursor: pointer;
  margin: 1%;
  align-self: center;
}

.submit:hover {
  background-color: #005699;
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}


.box{
  width: 47%;
  border-radius: 1%;
  height: -webkit-fill-available;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  max-height: 100%;

}

.navbar{
  background-color: #003366;
}



.bttns{
  display: flex;
  justify-content: space-between;
}
.homeButton{
  display: flex;
  justify-content: flex-end;
}

.tableTitle{
  margin-left: 1%;
  font-family: 'Helvetica Neue';
  font-size: 160%; 
  font-weight: 800; 

}
.authTitle{
  margin-left: 1%;
  font-family: 'Helvetica Neue';
  font-size: 160%; 

}
@media only screen and (max-width: 600px) {
  .muiButton {
    text-transform: capitalize;
  }
}
.title{
  width: 50%;
  display: flex;
  justify-content: center;
  flex: 12;
}
@media (max-width: 560px) {
  .logo {
    display: none; /* Hide the entire box when width is less than 560 pixels */
  }
  .title{
    margin-left: 0em;
  }
}